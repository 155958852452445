import React, { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

interface ResponsiveProps {
  children: ReactNode;
}
//반응형 대응

export const Mobile: React.FC<ResponsiveProps> = ({ children }) => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  return <>{isMobile && children}</>;
};

export const PC: React.FC<ResponsiveProps> = ({ children }) => {
  const isPc = useMediaQuery({
    query: "(min-width:769px)",
  });

  return <>{isPc && children}</>;
};
