import Typograpy from "components/Typograpy";
import { motion, useAnimation } from "framer-motion";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { colors } from "theme/colors";
import { Box, FlexBox, Img } from "theme/globalStyle";
import { useInView } from "react-intersection-observer";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const ImgArea = styled(motion.div)``;

export const IntroSectionSec = () => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });
  const [firImgMouseOver, setFirImgMouseOver] = React.useState(false);

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <FlexBox
      $flex={1}
      $flex_direction={isMobile ? "column-reverse" : "row"}
      $isFullWidth
      $ai="center"
      $jc="center"
      $gap={isMobile ? 5 : 8}
      $minHeight={`calc(100vh - ${isMobile ? "6.5" : "8.6"}rem)`}
      $py={isMobile ? 5 : 0}
    >
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
        }}
      >
        <FlexBox $flex={0} $ai="center" $jc="center" $flex_direction="row">
          <Box style={{ zIndex: firImgMouseOver ? 2 : 0 }}>
            <Img
              src={require("assets/img/intro_img1.png")}
              width={firImgMouseOver ? "120%" : "100%"}
              style={{ maxWidth: isMobile ? "32.7rem" : "unset" }}
              // className={"intro-img-fir"}
              onMouseEnter={() => {
                setFirImgMouseOver(true);
              }}
              onMouseLeave={() => {
                setFirImgMouseOver(false);
              }}
            />
          </Box>
          <Box $ml={-12}>
            <Img
              src={require("assets/img/intro_img2.png")}
              width={firImgMouseOver ? "80%" : "100%"}
              style={{ maxWidth: isMobile ? "32.7rem" : "unset" }}
            />
          </Box>
        </FlexBox>
      </motion.div>

      <FlexBox $flex={1} $gap={3} $ai={isMobile ? "center" : "flex-end"}>
        <FlexBox $ai={isMobile ? "center" : "flex-end"}>
          <Typograpy
            fontSize={isMobile ? 28 : 40}
            $lineHeight={isMobile ? 33.41 : 47.73}
            color={colors.BLACK}
            fontWeight="ExtraBold"
          >
            <Typograpy
              fontSize={isMobile ? 28 : 40}
              $lineHeight={isMobile ? 33.41 : 47.73}
              color={colors.BLUE}
              fontWeight="ExtraBold"
            >
              연말정산 관리
            </Typograpy>
            를 통한
          </Typograpy>
          <Typograpy
            fontSize={isMobile ? 28 : 40}
            $lineHeight={isMobile ? 33.41 : 47.73}
            color={colors.BLACK}
            fontWeight="ExtraBold"
          >
            최대 환급금 발생
          </Typograpy>
        </FlexBox>

        <Typograpy
          fontSize={isMobile ? 14 : 18}
          $lineHeight={isMobile ? 16.71 : 21.48}
          color={"#898989"}
          fontWeight="Medium"
        >
          복잡한 과정없이 셀프로 조회하고 청구하세요
        </Typograpy>
        <Link
          to="https://irphw.app.link/UzE9vHCRaNb"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Box
            $isPointer
            $bgcolor={colors.MAIN}
            $br={8}
            $px={5}
            $py={isMobile ? 1.5 : 2}
          >
            <Typograpy
              fontSize={isMobile ? 14 : 16}
              $lineHeight={isMobile ? 16.71 : 19.09}
              fontWeight="Bold"
              color={colors.WHITE}
            >
              환급금 조회하러 가기
            </Typograpy>
          </Box>
        </Link>
      </FlexBox>
    </FlexBox>
  );
};
