import { Contact } from "components/section/contact/Contact";
import React from "react";
import { FlexBox } from "theme/globalStyle";

export const ContactPage = () => {
  return (
    <FlexBox $isFullWidth>
      <Contact />
    </FlexBox>
  );
};
