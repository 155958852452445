import { Divider } from "components/Divider";
import { menuList } from "components/header/menuList";
import { FooterLogoIc } from "components/icon/icon";
import { AgreeModal } from "components/modal/agree/AgreeModal";
import Typograpy from "components/Typograpy";
import { color } from "framer-motion";
import { Mobile, PC } from "func/responsive";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { colors } from "theme/colors";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";
import ModalPortal from "utils/Portal";

export const Footer = () => {
  const [agreeModal, setAgreeModal] = React.useState<{
    type: "useble" | "privacy" | "";
    visible: boolean;
  }>({
    type: "",
    visible: false,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname.split("/")[1];

  return (
    <FlexBox $isFullWidth $bgcolor={colors.FOOTER_BG} $ai="center" $py={5}>
      <ModalPortal>
        <AgreeModal
          visible={agreeModal.visible}
          modalOff={() => {
            setAgreeModal({ type: "", visible: false });
          }}
          type={agreeModal.type}
        />
      </ModalPortal>

      <PC>
        <FlexBox
          $maxWidth={MainMaxWidth}
          $px={2}
          $flex_direction="row"
          $jc="space-between"
          $isFullWidth
        >
          <FlexBox $flex={1} $isFullWidth>
            <FooterLogoIc />
            <Box $mt={3}>
              <Typograpy
                fontWeight="SemiBold"
                color={colors.FOOTER_TITLE}
                fontSize={16}
                $lineHeight={19.09}
              >
                고객센터
              </Typograpy>
            </Box>
            <Typograpy
              fontSize={40}
              color={colors.WHITE}
              $lineHeight={47.73}
              fontWeight="Bold"
            >
              051-747-8563
            </Typograpy>
            <Box $mt={1}>
              <Typograpy
                fontSize={16}
                $lineHeight={19.09}
                color={colors.WHITE}
                fontWeight="Medium"
              >
                chris@bluelions.kr
              </Typograpy>
            </Box>
            <FlexBox $mt={2} $flex_direction="row" $ai="center" $gap={1.2}>
              <Box
                $isPointer
                onClick={() => {
                  setAgreeModal({ type: "privacy", visible: true });
                }}
              >
                <Typograpy
                  fontSize={14}
                  color={colors.WHITE}
                  fontWeight="Medium"
                >
                  개인정보처리방침
                </Typograpy>
              </Box>
              <Divider
                variant="normal"
                isVertical
                height={1.3}
                bgColor={colors.WHITE}
              />
              <Box
                $isPointer
                onClick={() => {
                  setAgreeModal({ type: "useble", visible: true });
                }}
              >
                <Typograpy
                  fontSize={14}
                  color={colors.WHITE}
                  fontWeight="Medium"
                >
                  이용약관
                </Typograpy>
              </Box>
            </FlexBox>
            <FlexBox $mt={2} $gap={0.4}>
              <FlexBox $flex_direction="row" $ai="center" $gap={1.2}>
                <Typograpy
                  fontSize={14}
                  color={colors.FOOTER_INFO}
                  fontWeight="Medium"
                >
                  (주)블루라이언스
                </Typograpy>
                <Divider
                  variant="normal"
                  isVertical
                  height={1.3}
                  bgColor={colors.FOOTER_DEVIDER}
                />
                <Typograpy
                  fontSize={14}
                  color={colors.FOOTER_INFO}
                  fontWeight="Medium"
                >
                  대표자 : 지환국
                </Typograpy>
              </FlexBox>
              <Typograpy
                fontSize={14}
                color={colors.FOOTER_INFO}
                fontWeight="Medium"
              >
                사업자등록번호 : 658-86-02418
              </Typograpy>
              <Typograpy
                fontSize={14}
                color={colors.FOOTER_INFO}
                fontWeight="Medium"
              >
                주소 : 부산광역시 남구 문현금융로40, 21층 10호(부산국제금융센터)
              </Typograpy>

              <Box $mt={2}>
                <Typograpy
                  fontSize={14}
                  color={colors.FOOTER_INFO}
                  fontWeight="Medium"
                >
                  CopyrightⓒBLUELIONS All Rights Reserved.
                </Typograpy>
              </Box>
            </FlexBox>
          </FlexBox>
          <FlexBox $gap={6} $flex={1} $flex_direction="row" $jc="flex-end">
            {menuList.map((item, index) => {
              return (
                <Box
                  onClick={() => {
                    navigate(item.link);
                  }}
                  $isPointer
                  key={"footer-pc-menu" + index}
                >
                  <Typograpy
                    fontWeight="Bold"
                    fontSize={18}
                    color={pathName === item.link ? colors.MAIN : colors.WHITE}
                  >
                    {item.label}
                  </Typograpy>
                </Box>
              );
            })}
          </FlexBox>
        </FlexBox>
      </PC>
      <Mobile>
        <FlexBox $isFullWidth>
          <Box $px={2}>
            <FooterLogoIc />
            <Box $mt={3}>
              <Typograpy
                fontWeight="SemiBold"
                color={colors.FOOTER_TITLE}
                fontSize={16}
                $lineHeight={19.09}
              >
                고객센터
              </Typograpy>
            </Box>
            <Typograpy
              fontSize={40}
              color={colors.WHITE}
              $lineHeight={47.73}
              fontWeight="Bold"
            >
              051-747-8563
            </Typograpy>
            <Box $mt={1}>
              <Typograpy
                fontSize={16}
                $lineHeight={19.09}
                color={colors.WHITE}
                fontWeight="Medium"
              >
                chris@bluelions.kr
              </Typograpy>
            </Box>
          </Box>
          <Box
            $mt={3}
            $btw={1}
            $bbw={1}
            $btc={colors.DEFAULT_BORDER_COLOR}
            $bbc={colors.DEFAULT_BORDER_COLOR}
            $isFullWidth
          >
            {menuList.map((item, index) => {
              return (
                <Box
                  $isPointer
                  $pa={2}
                  $bbw={menuList.length - 1 !== index ? 1 : 0}
                  $bbc={colors.DEFAULT_BORDER_COLOR}
                  key={"mobile-footer-menu" + index}
                  onClick={() => {
                    navigate(item.link);
                  }}
                >
                  <Typograpy
                    fontWeight="Bold"
                    fontSize={18}
                    color={pathName === item.link ? colors.MAIN : colors.WHITE}
                  >
                    {item.label}
                  </Typograpy>
                </Box>
              );
            })}
          </Box>

          <FlexBox
            $mt={2}
            $px={2}
            $flex_direction="row"
            $ai="center"
            $gap={1.2}
          >
            <Box
              $isPointer
              onClick={() => {
                setAgreeModal({ type: "privacy", visible: true });
              }}
            >
              <Typograpy fontSize={14} color={colors.WHITE} fontWeight="Medium">
                개인정보처리방침
              </Typograpy>
            </Box>
            <Divider
              variant="normal"
              isVertical
              height={1.3}
              bgColor={colors.WHITE}
            />
            <Box
              $isPointer
              onClick={() => {
                setAgreeModal({ type: "useble", visible: true });
              }}
            >
              <Typograpy fontSize={14} color={colors.WHITE} fontWeight="Medium">
                이용약관
              </Typograpy>
            </Box>
          </FlexBox>
          <FlexBox $py={3} $px={2} $gap={0.4}>
            <FlexBox $flex_direction="row" $ai="center" $gap={1.2}>
              <Typograpy
                fontSize={14}
                color={colors.FOOTER_INFO}
                fontWeight="Medium"
              >
                (주)블루라이언스
              </Typograpy>
              <Divider
                variant="normal"
                isVertical
                height={1.3}
                bgColor={colors.FOOTER_DEVIDER}
              />
              <Typograpy
                fontSize={14}
                color={colors.FOOTER_INFO}
                fontWeight="Medium"
              >
                대표자 : 지환국
              </Typograpy>
            </FlexBox>
            <Typograpy
              fontSize={14}
              color={colors.FOOTER_INFO}
              fontWeight="Medium"
            >
              사업자등록번호 : 658-86-02418
            </Typograpy>
            <Typograpy
              fontSize={14}
              color={colors.FOOTER_INFO}
              fontWeight="Medium"
            >
              주소 : 부산광역시 남구 문현금융로40, 21층 10호(부산국제금융센터)
            </Typograpy>

            <Box $mt={2}>
              <Typograpy
                fontSize={14}
                color={colors.FOOTER_INFO}
                fontWeight="Medium"
              >
                CopyrightⓒBLUELIONS All Rights Reserved.
              </Typograpy>
            </Box>
          </FlexBox>
        </FlexBox>
      </Mobile>
    </FlexBox>
  );
};
