import Typograpy from "components/Typograpy";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { colors } from "theme/colors";
import { Box, FlexBox, Img, MainMaxWidth } from "theme/globalStyle";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { AppleBlackIc, GooglePlayIc } from "components/icon/icon";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export const InduceStore = () => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });
  const navigate = useNavigate();

  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });

  //motion 컨테이너 셋팅
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      //보여질때 설정
      opacity: 1,
      transition: {
        staggerChildren: 0.3, // 각 자식 요소 사이의 지연 시간
      },
    },
  };

  const itemVariants = {
    //각 이미지 motion 설정
    hidden: { opacity: 0, y: 50 }, //초기엔 y위치가 50
    visible: {
      opacity: 1,
      y: 0, //보여지면 정위치로 이동
      transition: {
        duration: 0.5,
      },
    },
  };

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <FlexBox
      $flex={1}
      // $pt={isMobile ? 0 : 8.6}
      $flex_direction={isMobile ? "column" : "row"}
      $isFullWidth
      $ai="center"
      $jc="center"
      style={{
        background:
          "linear-gradient(117deg, #00B7FF 0.5%, #237BFF 36.09%, #0057DA 100.74%)",
      }}
    >
      <FlexBox
        $flex_direction={isMobile ? "column" : "row"}
        $ai={"center"}
        $jc={"center"}
        $maxWidth={MainMaxWidth}
        $gap={isMobile ? 5 : 10}
        $pt={isMobile ? 10 : 0}
        $pb={isMobile ? 4.5 : 0}
        $px={isMobile ? 0 : 2}
      >
        <FlexBox
          $flex={0}
          $gap={isMobile ? 3 : 5}
          $ai={isMobile ? "center" : "flex-start"}
        >
          <FlexBox $gap={0.5}>
            <Typograpy
              fontSize={isMobile ? 13 : 20}
              color={colors.WHITE}
              fontWeight="Regular"
            >
              블루버튼 서비스는 모두{" "}
              <Typograpy
                fontSize={isMobile ? 13 : 20}
                color={"#ffe24c"}
                fontWeight={isMobile ? "Medium" : "Bold"}
              >
                무료
              </Typograpy>
            </Typograpy>
            <FlexBox
              $flex_direction="row"
              $ai={isMobile ? "center" : "center"}
              $gap={isMobile ? 1 : 2}
            >
              <Img
                src={require("assets/img/bluebutton_logo.png")}
                width={isMobile ? 4 : 6.5}
                height={isMobile ? 4 : 6.5}
              />
              <Typograpy
                fontSize={isMobile ? 28 : 40}
                $lineHeight={isMobile ? 33.41 : 47.73}
                color={"transparent"}
                fontWeight="ExtraBold"
                style={{
                  background:
                    "linear-gradient(102deg, #FFF 32.22%, #D9E7FF 67.78%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  textDecoration: "none",
                }}
              >
                블루버튼
              </Typograpy>
            </FlexBox>
          </FlexBox>
          <FlexBox $gap={1.6}>
            <FlexBox $flex_direction="row" $ai={"center"} $gap={1}>
              <Img
                src={require("assets/img/ic_coin.png")}
                width={isMobile ? 2 : 3}
              />
              <Typograpy
                fontWeight="Bold"
                fontSize={isMobile ? 20 : 28}
                color={colors.WHITE}
              >
                연말정산 분석과 예측
              </Typograpy>
            </FlexBox>
            <FlexBox $flex_direction="row" $ai={"center"} $gap={1}>
              <Img
                src={require("assets/img/ic_coin.png")}
                width={isMobile ? 2 : 3}
              />
              <Typograpy
                fontWeight="Bold"
                fontSize={isMobile ? 20 : 28}
                color={colors.WHITE}
              >
                환급금 조회와 청구
              </Typograpy>
            </FlexBox>
            <FlexBox $flex_direction="row" $ai={"center"} $gap={1}>
              <Img
                src={require("assets/img/ic_coin.png")}
                width={isMobile ? 2 : 3}
              />
              <Typograpy
                fontWeight="Bold"
                fontSize={isMobile ? 20 : 28}
                color={colors.WHITE}
              >
                내 전체소득 확인 관리
              </Typograpy>
            </FlexBox>
            <FlexBox $flex_direction="row" $ai={"center"} $gap={1}>
              <Img
                src={require("assets/img/ic_coin.png")}
                width={isMobile ? 2 : 3}
              />
              <Typograpy
                fontWeight="Bold"
                fontSize={isMobile ? 20 : 28}
                color={colors.WHITE}
              >
                월급관리
              </Typograpy>
            </FlexBox>
          </FlexBox>
          <FlexBox $ai={isMobile ? "center" : "flex-start"} $gap={0.4}>
            <Typograpy
              fontSize={isMobile ? 28 : 40}
              fontWeight="ExtraBold"
              color={colors.WHITE}
            >
              복잡할 필요없이
            </Typograpy>
            <Typograpy
              fontSize={isMobile ? 28 : 40}
              fontWeight="ExtraBold"
              color={colors.WHITE}
            >
              이젠 간편하게 앱으로!
            </Typograpy>
          </FlexBox>
          <FlexBox $flex_direction="row" $gap={1.2}>
            <Link
              to="https://play.google.com/store/apps/details?id=com.ichis_mobile&hl=ko"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <FlexBox
                $isPointer
                $bgcolor={colors.WHITE}
                $br={8}
                $py={isMobile ? 1.5 : 2}
                width={isMobile ? 15 : 24}
                $ai="center"
                $jc="center"
                $flex_direction="row"
                $gap={0.8}
                $bw={2}
                $bc={colors.WHITE}
              >
                <GooglePlayIc
                  width={isMobile ? 1.6 : 1.8}
                  height={isMobile ? 2 : 1.8}
                />
                <Typograpy
                  fontSize={isMobile ? 14 : 16}
                  $lineHeight={isMobile ? 16.71 : 19.09}
                  fontWeight="Bold"
                  color={colors.BLACK}
                >
                  Google Play
                </Typograpy>
              </FlexBox>
            </Link>
            <Link
              to="https://apps.apple.com/kr/app/%EB%B8%94%EB%A3%A8%EB%B2%84%ED%8A%BC/id6673894821"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <FlexBox
                $isPointer
                $bgcolor={colors.WHITE}
                $br={8}
                $py={isMobile ? 1.5 : 2}
                width={isMobile ? 15 : 24}
                $ai="center"
                $jc="center"
                $flex_direction="row"
                $gap={0.8}
                $bw={2}
                $bc={colors.WHITE}
              >
                <AppleBlackIc
                  width={isMobile ? 1.44 : 1.6}
                  height={isMobile ? 1.8 : 2}
                />
                <Typograpy
                  fontSize={isMobile ? 14 : 16}
                  $lineHeight={isMobile ? 16.71 : 19.09}
                  fontWeight="Bold"
                  color={colors.BLACK}
                >
                  App Store
                </Typograpy>
              </FlexBox>
            </Link>
          </FlexBox>
        </FlexBox>

        <motion.div
          ref={ref}
          variants={containerVariants}
          initial="hidden"
          animate={controls}
          style={{ width: "80%" }}
        >
          <FlexBox $flex_direction="row" $ai="center" $gap={1.2}>
            <motion.div style={{ flex: 1 }} variants={itemVariants}>
              <Box $flex={1}>
                <Img
                  src={require("assets/img/induce_store1.png")}
                  width={"100%"}
                />
              </Box>
            </motion.div>
            <FlexBox $flex={1} $gap={2}>
              <motion.div style={{ width: "100%" }} variants={itemVariants}>
                <Img
                  src={require("assets/img/induce_store2.png")}
                  width={"100%"}
                />
              </motion.div>
              <motion.div variants={itemVariants}>
                <Img
                  src={require("assets/img/induce_store3.png")}
                  width={"100%"}
                />
              </motion.div>
            </FlexBox>
          </FlexBox>
        </motion.div>
      </FlexBox>
    </FlexBox>
  );
};
