import styled from "styled-components";
import { ReactComponent as IcLogo } from "assets/img/ic_logo.svg";
import { ReactComponent as IcFooterLogo } from "assets/img/ic_logo_footer.svg";
import { ReactComponent as IcGooglePlay } from "assets/img/ic_googleplay.svg";
import { ReactComponent as IcApple } from "assets/img/ic_apple.svg";
import { ReactComponent as IcAppleBlack } from "assets/img/ic_apple_black.svg";
import { ReactComponent as IcMenu } from "assets/img/ic_menu.svg";
import { ReactComponent as IcCloude } from "assets/img/ic_cloude.svg";
import { ReactComponent as IcLock } from "assets/img/ic_lock.svg";
import { ReactComponent as IcClose } from "assets/img/ic_close.svg";
import { ReactComponent as IcStar } from "assets/img/ic_star.svg";

interface IconPropsType {
  width?: number;
  height?: number;
}

const LogoIc = styled(IcLogo)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "4.3rem")};
  height: ${({ height }) => (height ? height + "rem" : "4.3rem")};
`;

const MenuIc = styled(IcMenu)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2.4rem")};
  height: ${({ height }) => (height ? height + "rem" : "2.4rem")};
`;

const GooglePlayIc = styled(IcGooglePlay)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.8rem")};
  height: ${({ height }) => (height ? height + "rem" : "2rem")};
`;

const AppleIc = styled(IcApple)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.6rem")};
  height: ${({ height }) => (height ? height + "rem" : "2rem")};
`;

const AppleBlackIc = styled(IcAppleBlack)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.6rem")};
  height: ${({ height }) => (height ? height + "rem" : "2rem")};
`;

const CloudeIc = styled(IcCloude)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "8rem")};
  height: ${({ height }) => (height ? height + "rem" : "6.8rem")};
`;

const LockIc = styled(IcLock)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "5.2rem")};
  height: ${({ height }) => (height ? height + "rem" : "7.5rem")};
`;

const CloseIc = styled(IcClose)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2.4rem")};
  height: ${({ height }) => (height ? height + "rem" : "2.4rem")};
`;

const FooterLogoIc = styled(IcFooterLogo)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "5rem")};
  height: ${({ height }) => (height ? height + "rem" : "5rem")};
`;

const StarIc = styled(IcStar)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2.4rem")};
  height: ${({ height }) => (height ? height + "rem" : "2.4rem")};
`;

export {
  FooterLogoIc,
  LogoIc,
  MenuIc,
  GooglePlayIc,
  AppleBlackIc,
  AppleIc,
  CloudeIc,
  LockIc,
  CloseIc,
  StarIc,
};
