import Typograpy from "components/Typograpy";
import { Mobile, PC } from "func/responsive";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { colors } from "theme/colors";
import { Box, FlexBox, Img, MainMaxWidth } from "theme/globalStyle";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { ShareEmpList } from "./list";

export const ShareData = () => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: false });

  const imgVariants = {
    hidden: { opacity: 0, scale: 0.3 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <FlexBox
      $flex={1}
      $pt={isMobile ? 0 : 8.6}
      $py={isMobile ? 2 : 0}
      $isFullWidth
      $ai="center"
      $jc="center"
      $gap={isMobile ? 5 : 10}
      $minHeight={`calc(100vh - ${isMobile ? "6.5" : "8.6"}rem)`}
    >
      <FlexBox
        $flex_direction={isMobile ? "column" : "row"}
        $ai={"center"}
        $jc={"center"}
        $maxWidth={MainMaxWidth}
        $gap={isMobile ? 5 : 10}
        $pt={isMobile ? 10 : 0}
        $px={isMobile ? 2 : 0}
        $pb={isMobile ? 4.5 : 10}
      >
        <FlexBox $ai={"center"} $isFullWidth>
          <Mobile>
            <Box $mb={2}>
              <Typograpy fontSize={28} fontWeight="ExtraBold">
                부산 직장인의 공유데이터
              </Typograpy>
            </Box>
          </Mobile>
          <FlexBox $isFullWidth>
            <PC>
              <FlexBox $position="absolute" $top={30} $left={0}>
                <Typograpy
                  fontSize={40}
                  fontWeight="ExtraBold"
                  color={colors.BLACK}
                >
                  부산 직장인의
                </Typograpy>
                <Typograpy
                  fontSize={40}
                  fontWeight="ExtraBold"
                  color={colors.BLACK}
                >
                  공유데이터
                </Typograpy>
              </FlexBox>
            </PC>
            <motion.div
              style={{ width: "100%", zIndex: -1 }}
              ref={ref}
              animate={controls}
              initial={"hidden"}
              variants={imgVariants}
              // initial={{ opacity: 0, scale: 0.2 }}
              // animate={{ opacity: 1, scale: 1 }}
              // transition={{
              //   duration: 0.8,
              //   ease: "easeOut",
              // }}
            >
              <Img
                src={require("assets/img/img_sharedata.jpg")}
                width={"100%"}
                height={isMobile ? 26 : 54}
                object_fit="contain"
              />
            </motion.div>
            <PC>
              <FlexBox
                $ai={"flex-end"}
                $position="absolute"
                $bottom={30}
                $right={0}
              >
                <Typograpy fontSize={18} fontWeight="SemiBold">
                  블루라이언스 공유 데이터는
                </Typograpy>
                <Typograpy fontSize={18} fontWeight="SemiBold">
                  부산 직장인 데이터 플랫폼인 블루버튼을 통해
                </Typograpy>
                <Typograpy fontSize={18} fontWeight="SemiBold">
                  부산 직장인의 산업적 인사이트로 제공하고자 합니다.
                </Typograpy>
              </FlexBox>
            </PC>
          </FlexBox>
          <Mobile>
            <FlexBox $ai={"center"} $mt={2}>
              <Typograpy fontSize={13} fontWeight="SemiBold">
                블루라이언스 공유 데이터는
              </Typograpy>
              <Typograpy fontSize={13} fontWeight="SemiBold">
                부산 직장인 데이터 플랫폼인 블루버튼을 통해
              </Typograpy>
              <Typograpy fontSize={13} fontWeight="SemiBold">
                부산 직장인의 산업적 인사이트로 제공하고자 합니다.
              </Typograpy>
            </FlexBox>
          </Mobile>

          <FlexBox $mt={10} $isFullWidth>
            <Typograpy fontSize={isMobile ? 28 : 40} fontWeight="Bold">
              공유데이터
            </Typograpy>
            <Box $mt={1}>
              <Typograpy fontSize={isMobile ? 15 : 20} fontWeight="Bold">
                부산 공유데이터의 보고서를 제공합니다.
              </Typograpy>
            </Box>
            <FlexBox $isFullWidth $mt={4} $gap={isMobile ? 4 : 5}>
              <FlexBox
                $isFullWidth
                $flex_direction="row"
                $jc={"space-between"}
                $ai={"center"}
              >
                <FlexBox>
                  <Typograpy
                    fontSize={isMobile ? 18 : 24}
                    fontWeight="SemiBold"
                    color={colors.FOOTER_DEVIDER}
                  >
                    현재 등록된 공유데이터가 존재하지 않습니다.
                  </Typograpy>
                  {/* <Typograpy fontSize={isMobile ? 18 : 24} fontWeight="SemiBold">
                  공유데이터 타이틀
                </Typograpy> */}
                  {/* <Mobile>
                  <Box $mt={0.8}>
                    <Typograpy
                      fontSize={14}
                      fontWeight="Medium"
                      color={colors.MOBILE_DATE_FONT}
                    >
                      2024-07-04
                    </Typograpy>
                  </Box>
                </Mobile> */}
                </FlexBox>
                {/* <PC>
                <Typograpy fontSize={16} fontWeight="SemiBold">
                  2024-07-04
                </Typograpy>
              </PC> */}
              </FlexBox>
              {/* <FlexBox
              $isFullWidth
              $flex_direction="row"
              $jc={"space-between"}
              $ai={"center"}
            >
              <FlexBox>
                <Typograpy fontSize={isMobile ? 18 : 24} fontWeight="SemiBold">
                  공유데이터 타이틀
                </Typograpy>
                <Mobile>
                  <Box $mt={0.8}>
                    <Typograpy
                      fontSize={14}
                      fontWeight="Medium"
                      color={colors.MOBILE_DATE_FONT}
                    >
                      2024-07-04
                    </Typograpy>
                  </Box>
                </Mobile>
              </FlexBox>
              <PC>
                <Typograpy fontSize={16} fontWeight="SemiBold">
                  2024-07-04
                </Typograpy>
              </PC>
            </FlexBox> */}
            </FlexBox>
          </FlexBox>
          <FlexBox $mt={10} $isFullWidth>
            <Typograpy fontSize={isMobile ? 28 : 40} fontWeight="Bold">
              데이터 공유와 협력업체
            </Typograpy>
            <Box $mt={1.2}>
              <Typograpy
                fontSize={isMobile ? 15 : 20}
                fontWeight="Bold"
                color={colors.SUB_FONT}
              >
                공유경제 활성화 지원 사업의 일환으로 지원을 받아 제작되었습니다.
              </Typograpy>
            </Box>

            <FlexBox
              $flex_direction="row"
              $ai={"center"}
              $jc={"center"}
              style={{ flexWrap: "wrap" }}
              $gap={1.2}
              $mt={2}
            >
              {ShareEmpList.map((item, index) => {
                return (
                  <FlexBox
                    $flex={1}
                    $bw={1}
                    $bc={colors.DEFAULT_BORDER_COLOR}
                    $px={1.2}
                    $py={0.8}
                    key={"empList" + index}
                    $jc={"center"}
                    $ai={"center"}
                  >
                    <Box
                      $minWidth={isMobile ? 18 : 20}
                      height={isMobile ? 5 : 6}
                      $maxWidth={isMobile ? 18 : 20}
                    >
                      <Img
                        src={item}
                        width={"100%"}
                        height={"100%"}
                        object_fit="contain"
                      />
                    </Box>
                  </FlexBox>
                );
              })}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
