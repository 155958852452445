import React from "react";

import Modal from "react-modal";
import { customModalStyles } from "../customModalStyles";
import { Box, FlexBox } from "theme/globalStyle";
import { agreeList } from "./agreeList";
import Typograpy from "components/Typograpy";
import styled from "styled-components";
import { colors } from "theme/colors";
import { CloseIc } from "components/icon/icon";
import { useMediaQuery } from "react-responsive";

interface AgreeModalPropsType {
  visible: boolean;
  modalOff: () => void;
  type: "" | "useble" | "privacy";
}

const AgreeWrapper = styled(FlexBox)`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const AgreeContent = styled.pre`
  font-family: "Regular";
  color: ${colors.BLACK};
  white-space: pre-wrap;

  line-height: 2rem;
`;

export const AgreeModal: React.FC<AgreeModalPropsType> = ({
  visible,
  modalOff,
  type,
}) => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  //modal 활성화시 body 스크롤 lock
  React.useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [visible]);

  return (
    <Modal isOpen={visible} onRequestClose={modalOff} style={customModalStyles}>
      {type !== "" && (
        <AgreeWrapper $isFullWidth>
          <FlexBox
            $mb={isMobile ? 0 : 3}
            $isFullWidth
            $flex_direction="row"
            $jc={"space-between"}
          >
            <Typograpy fontSize={isMobile ? 15 : 20} fontWeight="SemiBold">
              {agreeList[type].title}
            </Typograpy>
            <Box $isPointer onClick={modalOff}>
              <CloseIc />
            </Box>
          </FlexBox>

          <AgreeContent style={{ fontSize: isMobile ? "1.3rem" : "1.6rem" }}>
            {agreeList[type].content}
          </AgreeContent>
        </AgreeWrapper>
      )}
    </Modal>
  );
};
