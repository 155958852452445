import Typograpy from "components/Typograpy";
import { StarIc } from "components/icon/icon";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";

interface ReviewCardPropsType {
  reviewInfo: {
    id: string;
    rank: number;
    contents: string;
  };
}

export const ReviewCard: React.FC<ReviewCardPropsType> = React.memo(
  ({ reviewInfo }) => {
    const isMobile = useMediaQuery({
      query: "(max-width : 768px)",
    });

    return (
      <Box $pb={isMobile ? 2 : 3}>
        <FlexBox
          $py={isMobile ? 3 : 5}
          $px={3.5}
          width={isMobile ? 30 : 40}
          shadowType="strong"
          $bgcolor={colors.REVIEW_CARD_BG}
          $br={8}
          $minHeight={isMobile ? 20 : 25}
          $maxHeight={isMobile ? 20 : 25}
        >
          <FlexBox
            $flex_direction="row"
            $jc="space-between"
            $ai="center"
            $isFullWidth
          >
            <Typograpy fontSize={18} color={colors.BLUE} fontWeight="ExtraBold">
              {reviewInfo.id}
            </Typograpy>
            <FlexBox $flex_direction="row" $gap={0.4}>
              {Array.from({ length: reviewInfo.rank }).map((item, index) => {
                return (
                  <StarIc
                    key={"star" + index}
                    width={isMobile ? 1.8 : 2.4}
                    height={isMobile ? 1.8 : 2.4}
                  />
                );
              })}
            </FlexBox>
          </FlexBox>

          <Box
            $mt={2}
            $isFullWidth
            style={{ textWrap: "wrap" }}
            className={"multi-line-ellips"}
          >
            <Typograpy
              fontSize={18}
              color={colors.BLACK}
              style={{ lineHeight: isMobile ? "2.5rem" : "3.6rem" }}
              className={"multi-line-ellips"}
            >
              {reviewInfo.contents}
            </Typograpy>
          </Box>
        </FlexBox>
      </Box>
    );
  }
);
