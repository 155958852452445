import { ShareData } from "components/section/shareData/ShareData";
import { MarketingAgree } from "page/blueBtnAgree/MarketingAgree";
import { PrivacyAgree } from "page/blueBtnAgree/PrivacyAgree";
import { ServiceAgree } from "page/blueBtnAgree/ServiceAgree";
import { BlueBtnPage } from "page/BlueBtnPage";
import { ContactPage } from "page/ContactPage";
import { MainPage } from "page/MainPage";
import { createBrowserRouter, createHashRouter } from "react-router-dom";
import MainStatic from "static/MainStatic";

const rootPath = "/";

export const MasterRouter = createHashRouter([
  {
    path: rootPath,
    element: <MainStatic />,
    children: [
      {
        path: "",
        element: <MainPage />,
      },
      {
        path: "shareData",
        element: <ShareData />,
      },
      {
        path: "contact",
        element: <ContactPage />,
      },
    ],
  },
  {
    path: "service",
    element: <ServiceAgree />,
  },
  {
    path: "privacy",
    element: <PrivacyAgree />,
  },
  {
    path: "marketing",
    element: <MarketingAgree />,
  },
]);
