import { Intro } from "components/section/rending/Intro";
import { InduceStore } from "components/section/rending/InduceStore";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";
import { BlueButton } from "components/section/blueButton/BlueButton";
import RollingBanner from "components/RollingBanner";
import { dummyReviewList } from "components/section/shareData/list";
import { ReviewCard } from "components/review/ReviewCard";
import { Divider } from "components/Divider";
import { colors } from "theme/colors";
import { IntroSectionSec } from "components/section/rending/IntroSectionSec";
import { IntroSectionThr } from "components/section/rending/IntroSectionThr";

export const MainPage = () => {
  const isMobile = useMediaQuery({
    query: "(max-width : 768px)",
  });

  return (
    <FlexBox $isFullWidth $ai={"center"}>
      <FlexBox $ai={"center"} $isFullWidth $minHeight={"100vh"}>
        <InduceStore />
      </FlexBox>
      <Box $isFullWidth $pt={5}>
        <RollingBanner
          rolling={"left"}
          duration={60}
          bannerList={dummyReviewList.map((item, index) => {
            return (
              <ReviewCard key={"review" + index} reviewInfo={item}></ReviewCard>
            );
          })}
        />
        <RollingBanner
          rolling={"right"}
          duration={60}
          bannerList={dummyReviewList.map((item, index) => {
            return (
              <ReviewCard key={"review" + index} reviewInfo={item}></ReviewCard>
            );
          })}
        />
      </Box>
      <FlexBox $px={2} $ai={"center"} $isFullWidth $maxWidth={MainMaxWidth}>
        <Intro />
      </FlexBox>
      <Divider
        height={isMobile ? 1.5 : 3}
        variant="thick"
        bgColor={colors.DEFAULT_LIGHT_BORDER_COLOR}
        width={"100%"}
      />
      <FlexBox $px={2} $ai={"center"} $isFullWidth $maxWidth={MainMaxWidth}>
        <IntroSectionSec />
      </FlexBox>
      <Divider
        height={isMobile ? 1.5 : 2}
        variant="thick"
        bgColor={colors.DEFAULT_LIGHT_BORDER_COLOR}
        width={"100%"}
      />
      <FlexBox $px={2} $ai={"center"} $isFullWidth $maxWidth={MainMaxWidth}>
        <IntroSectionThr />
      </FlexBox>
      {/* <FlexBox $px={2} $ai={"center"} $isFullWidth $maxWidth={MainMaxWidth}>
        <BlueButton />
      </FlexBox> */}
    </FlexBox>
  );
};
