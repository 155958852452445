export const colors = {
  BLACK: "#000",
  WHITE: "#FFF",
  RED: "#FF4242",
  MAIN: "#2E3192",
  BLUE: "#06f",
  CANCLE: "#9397A1",
  REQUIRE_COLOR: "#D33444",
  REQUIRE_FONT_COLOR: "rgba(102, 102, 102, 0.61)",

  DEFAULT_FONT_COLOR: "#000",
  DEFAULT_BORDER_COLOR: "rgba(112, 115, 124, 0.22)",
  DEFAULT_LIGHT_BORDER_COLOR: "#F5F6F8",
  DIVIDER_COLOR: "#D9D9D9",

  MOBILE_DATE_FONT: "#898989",

  SUB_FONT: "#898989",

  //footer
  FOOTER_TITLE: "#A5A5A5",
  FOOTER_BG: "#000000",
  FOOTER_INFO: "#5F5F5F",
  FOOTER_DEVIDER: "#5F5F5F",

  GOOGLE_PLAY_BG: "#F9FAFC",

  GRAY_BG: "#F9FAFC",

  BTN_DISABLE: "#D9D9D9",

  REVIEW_CARD_BG: "#F3F4F6",
  REVIEW_CARD_ID: "#5C60C8",
  REVIEW_SEL_BORDER: "rgba(112, 115, 124, 0.52)",
};
